const Section = ({
  children,
  direction = "column",
  accentColor = false,
  fillScreen = false,
  id,
}: SectionProps) => {
  return (
    <div
      id={id}
      className={`flex gap-6 px-20 py-6 items-center justify-center max-lg:px-10 max-md:px-1 max-md:py-1 ${
        direction === "column" ? "flex-col" : "flex-row"
      }
      ${accentColor ? "bg-accent" : ""}
      ${fillScreen ? "min-h-screen" : ""}
      `}
    >
      {children}
    </div>
  );
};

interface SectionProps {
  children?: JSX.Element[] | JSX.Element | string;
  direction?: "column" | "row";
  accentColor?: boolean;
  id?: string;
  fillScreen?: boolean;
}
export default Section;
