import Card from "components/Card";
import Section from "components/Section";
import SectionTitle from "components/SectionTitle";

const AboutUs = (): JSX.Element => (
  <>
    <Section>
      <SectionTitle>About us</SectionTitle>
    </Section>

    <Section direction="column" accentColor fillScreen>
      <Card
        enableAnimation
        variant="row"
        hideBg
        icon={
          <img
            src="/assets/about-us-hands.png"
            className="w-full h-auto rounded-lg max-w-[90%]"
            alt="hero"
          />
        }
        title={
          <>
            We at <span className="text-secondary">ResolveX</span>
          </>
        }
        description="envision a world where no one feels isolated and unsupported during emotional distress and crisis. We do this by reducing and eliminating barriers to connection through beautifully designed, intuitive, and user-friendly products."
      />

      <Card
        enableAnimation
        animationDelay={0.2}
        variant="row"
        hideBg
        reverse
        icon={
          <img
            src="/assets/about-us-heart.png"
            className="w-full h-auto rounded-lg max-w-[90%]"
            alt="hero"
          />
        }
        description={
          <>
            To bridge the gap between the mental health field and technology our
            founder <strong className="font-bold">Tyler Castle</strong> has
            spent thousands of hours de-escalating over 10,000 suicidal
            individuals on the National Suicide Prevention Hotline (988) network
            and other behavioral health hotlines. This first-hand experience
            allows us to translate industry knowledge of what is likely to work
            in real-life situations and scenarios on calls rapidly into
            actionable technology.
          </>
        }
      />

      <Card
        enableAnimation
        animationDelay={0.2}
        variant="row"
        hideBg
        icon={
          <img
            src="/assets/about-us-data.png"
            className="w-full h-auto rounded-lg max-w-[90%]"
            alt="hero"
          />
        }
        description={
          <>
            <strong className="text-secondary font-bold">ResolveX</strong>&nbsp;
            focuses on providing data-driven, best practice products for suicide
            prevention. We reduce suicide hotline worker inputs so they can
            focus on the person in crisis rather than the backend data
            documentation that organizations rely on for funding. These services
            alleviate burdens suicide prevention hotline workers face in
            high-stress, high-stake environments where inefficiencies cost
            lives.
          </>
        }
      />
    </Section>
  </>
);

export default AboutUs;
