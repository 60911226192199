import Card from "components/Card";
import Hero from "components/Hero";
import Section from "components/Section";
import SectionTitle from "components/SectionTitle";
import { MdOutlineSupportAgent } from "react-icons/md";
import { PiSmileyBold } from "react-icons/pi";
import { CONTACT_URL } from "utils/constant";

const Home = (): JSX.Element => (
  <>
    <Hero />
    <Section>
      <SectionTitle>Expert-Designed Simplicity for Maximum Impact</SectionTitle>
    </Section>

    <Section direction="column">
      <div className="flex flex-1 w-full flex-row max-lg:flex-col gap-12 max-md:gap-6 items-stretch max-lg:items-center justify-center">
        <Card
          enableAnimation
          variant="column"
          icon={
            <MdOutlineSupportAgent className="text-primary text-[152px] max-lg:text-[90px]" />
          }
          title="Built by call takers for call takers"
          description="Our founder alone has personally taken over 10,000 calls, to
          understand your needs and what it's like on a call-by-call basis."
        />

        <Card
          enableAnimation
          animationDelay={0.2}
          variant="column"
          icon={
            <PiSmileyBold className="text-primary text-[152px] max-lg:text-[90px]" />
          }
          title="Ease of use"
          description=" Former intel software engineers and Nike designers to make our
          platform look and feel seamless."
        />
      </div>
    </Section>

    <Section id="why-us">
      <SectionTitle>
        Streamlined Solutions for Enhanced Efficiency and Security
      </SectionTitle>
    </Section>

    <Section accentColor>
      <div className="flex flex-1 flex-col items-center w-full min-h-screen gap-16">
        <Card
          enableAnimation
          variant="row"
          icon={
            <img
              src="/assets/why-us-docs.png"
              className="w-full h-auto rounded-lg max-w-[90%]"
              alt="hero"
            />
          }
          title="Documentation"
          description={
            <>
              <strong>
                14 different workflows: Allowing all lines of business to be
                documented in one place
              </strong>
              <br />
              <br />
              Unified documentation guarantee: If you have additional workflows,
              we'll help map them out and build a custom workflow.
              <br />
              <br />
              <strong>
                Align your team with timesheets, call notifications, and
                integrated phone system API’s.
              </strong>
            </>
          }
        />

        <Card
          enableAnimation
          animationDelay={0.2}
          variant="row"
          icon={
            <img
              src="/assets/why-us-reporting.png"
              className="w-full h-auto rounded-lg max-w-[90%]"
              alt="hero"
            />
          }
          reverse
          title="Reporting"
          description={
            <>
              <strong>Data</strong>
              <br />
              Hippa Compliant: For peace of mind even if your workflows don’t
              require it.
              <br />
              <br />
              Security: Standard 2FA authentication for all users.
              <br />
              <br />
              Quality assurance: Automated quality checks that alert call takers
              when information is missing.
              <br />
              <br />
              <strong>Automated reporting</strong>
              <br />
              Build a report once and get it emailed to you or your stakeholders
              at regular intervals.
            </>
          }
        />

        <Card
          enableAnimation
          animationDelay={0.2}
          variant="row"
          icon={
            <img
              src="/assets/why-us-savings.png"
              className="w-full h-auto rounded-lg max-w-[90%]"
              alt="hero"
            />
          }
          title="Operation ROI & Savings"
          description={
            <>
              <strong>Scalability</strong>
              <br />
              Full remote capability for 24/7 uptime. Add a new user in 3 clicks
              or less.
              <br />
              <br />
              $250k average customer savings
              <br />
              <br />
              500% Increased documentation speed
            </>
          }
        />
      </div>
    </Section>

    <Section>
      <div className="py-20 w-full h-full flex flex-col gap-8 items-center justify-center">
        <h6 className="font-title font-bold text-primay text-opacity-75 text-3xl text-center">
          Empower Your Mission, Transform Your Workflow
        </h6>
        <p className="font-sub-title text-content text-opacity-75 text-lg text-center">
          Contact our sales team to explore heroic solutions and magical
          results.
        </p>
        <a
          href={CONTACT_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary max-w-lg w-full uppercase"
        >
          Contact sales
        </a>
      </div>
    </Section>
  </>
);

export default Home;
