const SectionTitle = ({ children }: SectionTitleProps) => {
  return (
    <h6 className="font-title font-bold text-content text-opacity-75 text-xl text-center">
      {children}
    </h6>
  );
};

interface SectionTitleProps {
  children?: string | JSX.Element;
}

export default SectionTitle;
