import { FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Section from "./Section";

const Footer = () => {
  return (
    <Section>
      <header
        className={`w-full flex gap-6 py-4 px-8 flex-row items-center justify-between`}
      >
        <div className="flex flex-row gap-6 items-center">
          <Link to="/">
            <img
              className="w-14 h-auto"
              src="/assets/resolvex-logo-sm.png"
              alt="resolvex-logo"
            />
          </Link>
        </div>

        <div className="flex flex-row gap-6 items-center">
          <Link
            to="https://www.linkedin.com/company/resolvex-inc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="w-8 h-auto text-primary opacity-75" />
          </Link>
        </div>
      </header>
    </Section>
  );
};

export default Footer;
