import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const Card = ({
  icon,
  title,
  description,
  variant = "column",
  reverse = false,
  enableAnimation = false,
  hideBg = false,
  animationDelay = 0,
}: CardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  const CardColumn = () => (
    <motion.div
      animate={
        enableAnimation && isInView ? { opacity: [0, 0.2, 1], y: [200, 0] } : {}
      }
      transition={{ ease: "easeOut", duration: 0.3, delay: animationDelay }}
      ref={ref}
      className={`flex ${
        reverse ? "flex-col-reverse" : "flex-col"
      }  flex-1 gap-4 max-w-[540px] py-10 px-2`}
    >
      <div className="flex flex-col w-full h-full">
        {icon}

        <h6 className="w-full text-left font-title font-bold text-content text-opacity-75 text-4xl max-lg:text-xl">
          {title}
        </h6>
      </div>
      <div className="bg-secondary divider rounded-lg max-w-20 max-h-2" />
      <p className="w-full text-left font-sub-title text-content text-opacity-75 text-[16px]">
        {description}
      </p>
    </motion.div>
  );

  const CardRow = () => (
    <motion.div
      animate={
        enableAnimation && isInView
          ? { opacity: [0, 0.2, 1], x: [reverse ? -200 : 200, 0] }
          : {}
      }
      transition={{ ease: "easeOut", duration: 0.3, delay: animationDelay }}
      ref={ref}
      className={`flex ${
        reverse ? "flex-row-reverse" : "flex-row "
      } w-full gap-4 py-10 px-2 max-w-[1080px] max-lg:flex-col`}
    >
      <div className="flex flex-col flex-1 h-full gap-6">
        <h6
          className={`w-full ${
            icon ? "max-w-md" : ""
          } text-left font-title font-bold text-primary text-opacity-75 text-4xl`}
        >
          {title}
        </h6>
        <p
          className={`w-full ${
            icon ? "max-w-md" : ""
          }  text-left font-sub-title text-content text-opacity-75 text-[16px]`}
        >
          {description}
        </p>
      </div>
      {icon && (
        <div
          className={`z-[1] relative flex flex-1 flex-col justify-center rounded-lg overflow-hidden ${
            hideBg ? "items-center" : "items-start"
          }`}
        >
          {icon}
          {!hideBg && (
            <div
              className={`${
                reverse ? "translate-x-[-50%]" : "translate-x-[50%]"
              } z-[-1] absolute flex flex-1 flex-col items-start justify-center gap-1 bg-secondary aspect-video w-full h-auto rounded-full`}
            />
          )}
        </div>
      )}
    </motion.div>
  );

  switch (variant) {
    case "column":
      return <CardColumn />;
    case "row":
      return <CardRow />;
    default:
      return <CardColumn />;
  }
};

interface CardProps {
  icon?: JSX.Element;
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  variant?: "column" | "row";
  reverse?: boolean;
  enableAnimation?: boolean;
  animationDelay?: number;
  hideBg?: boolean;
}

export default Card;
