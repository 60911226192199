import { GiHealthNormal } from "react-icons/gi";
import { FaPhone } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { CONTACT_URL } from "utils/constant";
import { motion } from "framer-motion";

const HeroText = () => {
  return (
    <div className="w-1/2 flex flex-col flex-1 items gap-10 max-lg:gap-10 max-md:gap-5 px-20 justify-center max-md:px-8 max-md:w-full max-md:items-center">
      <motion.h5
        animate={{ opacity: [0, 1], y: [-50, 0] }}
        transition={{ duration: 0.5 }}
        className="text-[40px] max-md:text-lg max-md:text-center max-lg:text-2xl"
      >
        How you document, report and operate matters.
      </motion.h5>
      <motion.h5
        animate={{ opacity: [0, 1], y: [-50, 0] }}
        transition={{ duration: 0.5 }}
        className="text-[40px] font-bold max-md:text-lg max-lg:text-2xl max-md:text-center"
      >
        Do it together with
        <span className="font-bold text-secondary">&nbsp;ResolveX</span>.
      </motion.h5>
      <motion.a
        animate={{ opacity: [0, 1], y: [50, 0] }}
        transition={{ duration: 0.5, delay: 0.5 }}
        href={CONTACT_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary btn-lg max-w-lg w-full uppercase max-md:btn-sm max-lg:btn-md"
      >
        Book a demo
      </motion.a>
    </div>
  );
};

const HeroImage = () => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center max-md:justify-start max-md:w-full max-md:items-center ">
      <motion.div
        animate={{ opacity: [0, 1], x: [500, 0] }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-start justify-center gap-1 bg-secondary aspect-video w-full h-auto rounded-l-[50%] max-md:rounded-l-[45%] max-lg:px-1"
      >
        <motion.img
          animate={{ opacity: [0, 1], y: [50, 0] }}
          transition={{ duration: 0.8, delay: 0.5, type: "spring" }}
          src="/assets/hero-image-aspect-video.png"
          className="aspect-video w-full max-w-2xl rounded-lg max-lg:w-[95%]"
          alt="hero"
        />
        <motion.div
          animate={{ opacity: [0, 1], y: [50, 0] }}
          transition={{ duration: 0.8, delay: 0.8, type: "spring" }}
          className="aspect-video w-full max-w-2xl h-6 flex flex-row items-center justify-end gap-12 rounded-lg max-lg:w-[95%] px-1"
        >
          <GiHealthNormal className="text-xl text-primary max-lg:text-lg" />
          <FaPhone className="text-xl text-primary max-lg:text-lg" />
          <IoPerson className="text-xl text-primary max-lg:text-lg" />
        </motion.div>
      </motion.div>
    </div>
  );
};

const Hero = () => {
  return (
    <div className="w-screen h-screen max-w-full max-md:min-h-screen max-xl:h-full max-xl:min-h-[640px] flex flex-row items-stretch justify-center max-md:flex-col max-md:items-center max-md:justify-start max-md:gap-4">
      <HeroText />
      <HeroImage />
    </div>
  );
};

export default Hero;
