import ScrollToId from "components/ScrollToId";
import AboutUs from "pages/AboutUs";
// import ContactUs from "pages/ContactUs";
import Home from "pages/Home";
import Root from "pages/Root";
import Services from "pages/Services";
import { createHashRouter as createRouter } from "react-router-dom";
import { RouteObjectWithLabel } from "types";

export const routes: RouteObjectWithLabel[] = [
  {
    path: "/about-us",
    element: <AboutUs />,
    label: "About us",
  },
  {
    path: "/why-us",
    label: "Why us",
    element: <ScrollToId id="why-us" element={<Home />} />,
  },
  {
    path: "/services",
    element: <Services />,
    label: "Services",
  },
  {
    path: "/",
    element: <Home />,
  },
];

const router = createRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <div className="w-full h-screen flex items-center justify-center">
        <h3 className="text-2xl font-bold">Nothing here. Just go back. 🙊</h3>
      </div>
    ),
    children: routes,
  },
]);

export default router;
