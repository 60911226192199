import { useAnimation, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const FloatingButton = ({ idToScroll }: FloatingButtonProps) => {
  const controls = useAnimation();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true);
        controls.start({ opacity: 1, scale: 1 });
      } else {
        setVisible(false);
        controls.start({ opacity: 0, scale: 0 });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    if (idToScroll) {
      const element = document.getElementById(idToScroll);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }

      return;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={controls}
      className="fixed bottom-4 right-4 z-50"
    >
      {visible && (
        <button className="btn btn-primary btn-md max-md:btn-sm rounded-full shadow-xl">
          <FaArrowUp
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
            }}
          />
        </button>
      )}
    </motion.div>
  );
};

interface FloatingButtonProps {
  idToScroll: string;
}

export default FloatingButton;
