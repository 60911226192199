import { useEffect } from "react";

const ScrollToId = ({ id, element }: ScrollToDivProps) => {
  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  return element; // This component does not render anything
};

interface ScrollToDivProps {
  id: string;
  element: JSX.Element;
}

export default ScrollToId;
