import FloatingButton from "components/FloatingButton";
import Footer from "components/Footer";
import Header from "components/Header";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { routes } from "routes";
import { WEB_TITLE } from "utils/constant";

const Root = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const label =
      routes.find((routes) => routes?.path === location.pathname)?.label || "";

    document.title = label ? WEB_TITLE + " | " + label : WEB_TITLE;
  }, [location.pathname]);

  return (
    <main className="flex flex-col gap-4">
      <Header />
      <FloatingButton idToScroll="header" />
      <Outlet />
      <Footer />
    </main>
  );
};

export default Root;
