import { Link } from "react-router-dom";
import Section from "./Section";
import { routes } from "../routes";
import Drawer from "./Drawer";
import { PLATFORM_URL } from "utils/constant";

const Header = () => {
  return (
    <Section>
      <header
        id="header"
        className={`w-full flex gap-6 py-4 px-8 flex-row items-center justify-between`}
      >
        <div className="flex flex-row gap-6 items-center">
          <Drawer />
          <Link to="/">
            <img
              className="max-md:hidden w-14 h-auto"
              src="/assets/resolvex-logo-sm.png"
              alt="resolvex-logo"
            />
          </Link>
          {routes
            .filter((r) => r.path !== "/")
            .map((route) => {
              return (
                <Link
                  key={route.path}
                  className="link link-primary text-[16px] no-underline font-bold font-title max-md:hidden"
                  to={route.path || ""}
                >
                  {route.label}
                </Link>
              );
            })}
        </div>

        <Link to="/">
          <img
            className="hidden max-md:block w-14 h-auto"
            src="/assets/resolvex-logo-sm.png"
            alt="resolvex-logo"
          />
        </Link>

        <a
          href={PLATFORM_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="max-md:hidden btn btn-outline btn-primary uppercase btn-sm max-md:btn-xs"
        >
          Go to Platform
        </a>
      </header>
    </Section>
  );
};

export default Header;
