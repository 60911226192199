import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { CONTACT_URL, PLATFORM_URL } from "utils/constant";

const Drawer = () => {
  const handleLinkClick = () =>
    document.getElementById("mobile-drawer")?.click();

  return (
    <div className="drawer hidden max-md:block">
      <input id="mobile-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <label htmlFor="mobile-drawer" className="drawer-button cursor-pointer">
          <GiHamburgerMenu className="text-2xl text-primary" />
        </label>
      </div>
      <div className="drawer-side z-10">
        <label
          htmlFor="mobile-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="p-4 w-80 h-screen flex flex-col bg-base-100 gap-8">
          <li className="w-full flex flex-col justify-end">
            <Link to="/" onClick={handleLinkClick}>
              <img
                className="w-14 h-auto"
                src="/assets/resolvex-logo-sm.png"
                alt="resolvex-logo"
              />
            </Link>
          </li>

          {routes
            .filter((r) => r.path !== "/")
            .map((route, index) => {
              return (
                <li
                  key={route?.label || "" + index}
                  className="flex flex-row w-full justify-between items-center"
                >
                  <Link
                    key={route.path}
                    className="w-full link link-primary text-[12px] no-underline font-bold font-title"
                    to={route.path || ""}
                    onClick={handleLinkClick}
                  >
                    {route.label}
                  </Link>
                  <FaChevronRight className="text-primary text-sm" />
                </li>
              );
            })}

          <li className="w-full h-full flex flex-col justify-end gap-4">
            <a
              href={CONTACT_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-sm"
            >
              Contact Sales
            </a>
            <a
              href={PLATFORM_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-outline btn-sm"
            >
              Go to Platform
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
