import Card from "components/Card";
import Section from "components/Section";
import SectionTitle from "components/SectionTitle";
import { CONTACT_URL } from "utils/constant";

const Services = (): JSX.Element => (
  <>
    <Section>
      <SectionTitle>
        Transforming Operations with Tailored Software and Expert Consultation
      </SectionTitle>
    </Section>

    <Section direction="column" accentColor fillScreen>
      <div className="flex flex-1 flex-col justify-start items-center w-full h-full">
        <Card
          enableAnimation
          variant="row"
          hideBg
          title="Comprehensive Software Solutions"
          description={
            <>
              <strong>Custom-Designed for Real Challenges</strong>
              <br />
              <br />
              <strong>Streamlined Workflow Automation</strong>
              <br />
              Leveraging deep industry knowledge, our software automates crucial
              workflows, reducing manual input and errors while boosting overall
              efficiency.
              <br />
              <br />
              <strong>Advanced Data Management and Analytics</strong>
              <br />
              With precision-engineered tools, ResolveX transforms raw data into
              clear, actionable insights, helping you make better decisions
              faster.
              <br />
              <br />
              <strong>Seamless Integration</strong>
              <br />
              Our software effortlessly integrates with your existing systems,
              enhancing functionality and user experience without disrupting
              ongoing operations.
              <br />
              <br />
              <strong>Uncompromised Security</strong>
              <br />
              Built with the highest security standards, including HIPAA
              compliance and standard 2FA authentication, ensuring your data is
              protected at all times.
            </>
          }
        />
        <Card
          enableAnimation
          reverse
          variant="row"
          hideBg
          title="Consultative Services"
          description={
            <>
              <strong>Partnering for Success</strong>
              <br />
              <br />
              <strong>Expert Implementation</strong>
              <br />
              From strategy development to implementation, our team offers
              guidance based on extensive field experience to ensure your
              technology fully aligns with business goals.
              <br />
              <br />
              <strong>Continuous Support and Enhancement</strong>
              <br />
              ResolveX stands by its clients with ongoing support and regular
              updates, ensuring your solutions evolve with your needs.
              <br />
              <br />
              <strong>Custom Development</strong>
              <br />
              No matter the requirement, our team is ready to develop bespoke
              solutions that fit perfectly within your operational framework.
            </>
          }
        />
        <Card
          enableAnimation
          variant="row"
          hideBg
          title="Why Choose ResolveX?"
          description={
            <>
              <strong>Expertise at Every Step</strong>
              <br />
              From former intel software engineers to emergency services
              experts, our team is uniquely equipped to understand and build
              solutions that address the nuances of your critical operations.
              <br />
              <br />
              <strong>Client-Centric Innovations</strong>
              <br />
              We focus on creating solutions that are intuitive and easy to use,
              minimizing learning curves and maximizing adoption rates.
              <br />
              <br />
              <strong>Proven Impact</strong>
              <br />
              Our clients see dramatic improvements in operational efficiency
              and cost savings, with documented results like a 500% increase in
              documentation speed and average savings of $250k.
            </>
          }
        />
      </div>
    </Section>
    <Section fillScreen>
      <div className="py-20 w-full h-full flex flex-col gap-8 items-center justify-center">
        <h6 className="font-title font-bold text-primary text-opacity-75 text-3xl text-center">
          Engage with ResolveX
        </h6>
        <h6 className="font-title text-primary text-opacity-75 text-3xl text-center">
          Empower Your Team, Elevate Your Operations
        </h6>
        <p className="font-sub-title text-content text-opacity-75 text-lg text-center">
          Explore what ResolveX can do for you. Contact us today to discuss your
          needs, schedule a demo, or start transforming your operations with
          solutions built for success.
        </p>
        <a
          href={CONTACT_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary max-w-lg w-full uppercase"
        >
          Contact sales
        </a>
      </div>
    </Section>
  </>
);

export default Services;
